<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<CRow v-if="requiredCategory" class="mb-3">
			<CCol md="9">
				<h4 class="widget-title">
					Link to category
				</h4>
				<p class="widget-description">
					This function is able to select a category only level 1 to link with this microsite.
				</p>
			</CCol>
			<CCol md="12">
				<BaseDropDown
					v-model="localCategory"
					:options="categoryOptions"
					:searchable="false"
					label-drop-down="Category (Level 1)"
					label="name"
					track-by="value"
					placeholder="Selection"
					class="category-option"
				/>
			</CCol>
			<CCol md="12">
				<hr class="mb-4">
			</CCol>
		</CRow>
		<CRow class="mb-3">
			<CCol md="9">
				<h4 class="widget-title">
					Default widget
				</h4>
			</CCol>
		</CRow>

		<CRow>
			<CCol class="table-wrapper">
				<BaseTable
					:fields="DEFAULT_WIDGET_FIELDS"
					:items="widgetDefaultTableData"
					class="table-custom widget-table-default"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t('global.searchNotFound', {field: 'widget'}) }}
							</p>
						</div>
					</template>
					<template #type="{item}">
						{{ WIDGET_TYPE_LABEL[item] }}
					</template>
					<template #linkTo="{item}">
						<router-link
							:to="handleLink(item.id)"
							class="link-button"
						>
							{{ item.name }}
						</router-link>
					</template>
				</BaseTable>
			</CCol>
		</CRow>

		<CRow class="mt-4 mb-2">
			<CCol md="9">
				<h4 class="widget-title">
					Other widgets
				</h4>
				<p class="widget-description">
					{{ otherWidgetDescription }}
				</p>
			</CCol>
			<CCol md="3">
				<CDropdown
					add-menu-classes="import-dropdown-menu"
					class="text-right btn-dropdown"
					color="secondary"
				>
					<CDropdownItem @click="openModalCreate('shortcut_banner')">
						Shortcut
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('highlight_banner')">
						Highlight banner
					</CDropdownItem>
					<CDropdownItem v-if="!isMicrosite" @click="openModalCreate('small_banner')">
						Small banner
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('shop_by_brand_banner')">
						Shop by brand banner
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('products')">
						Product
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('highlight_content_block')">
						Highlight content block
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('stripe_content_block')">
						Stripe content block
					</CDropdownItem>
					<CDropdownItem @click="openModalCreate('text_with_image')">
						Text with Image content block
					</CDropdownItem>
					<CDropdownItem v-if="isMicrosite" @click="openModalCreate('content_banner')">
						Content banner
					</CDropdownItem>
					<template #toggler-content>
						<CIcon class="mr-2" name="cil-plus" />Create widget
					</template>
				</CDropdown>
			</CCol>
		</CRow>

		<CRow>
			<CCol class="table-wrapper">
				<BaseTable
					:fields="OTHER_WIDGET_FIELDS"
					:items="widgetOtherTableData"
					class="table-custom widget-table-other"
					is-draggable
					@drag="handleUpdateOrder"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t('global.searchNotFound', {field: 'widget'}) }}
							</p>
						</div>
					</template>
					<template #name="{item}">
						<strong>{{ item }}</strong>
					</template>
					<template #type="{item}">
						{{ WIDGET_TYPE_LABEL[item] }}
					</template>
					<template #linkTo="{item}">
						<router-link
							v-if="item.name"
							:to="handleLink(item.id)"
							class="link-button"
							target="_blank"
						>
							{{ item.name }}
						</router-link>
						<div v-else-if="item.type === WIDGET_TYPE.flashSale" class="typo-body-2 color-black-45">
							No ongoing flash sale
						</div>
						<div v-else-if="item.type === WIDGET_TYPE.brandBanner || item.type === WIDGET_TYPE.productHighlight" class="typo-body-2 color-black-45">
							N/A
						</div>
						<div v-if="item.status" class="link-status">
							{{ item.status }}
						</div>
					</template>
					<template #websiteVisibility="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
					<template #mobileVisibility="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
					<template #edit="{item}">
						<div class="text-right">
							<CButton
								v-if="item.editable"
								class="transparent p-0 btn-edit"
								@click="handleEdit(item.id)"
							>
								<CIcon class="icon-pencil" name="cil-pencil" />
							</CButton>
						</div>
					</template>
				</BaseTable>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading"
			content-class="main-wrapper"
			is-edit
			@onConfirm="handleSubmit"
			@onCancel="handleReset"
		/>

		<ModalCreateWidget
			ref="modal-create"
			:handle-create="handleCreateAndEdit"
			:title="modalTitle"
			:type="editedType"
			:is-edit="isEdit"
			:is-microsite="isMicrosite"
			@onSuccess="fetchWidgets"
			@onRemove="handleRemove"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteWidget.bind(null, { id: editedId, isMicrosite })"
			title="Remove this widget?"
			description="By removing this, the widget will be disappeared from the list and linked items will be lost"
			@onSuccess="fetchWidgets"
		/>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ModalCreateWidget from '@/components/ModalCreateWidget.vue';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';

import { ROUTE_NAME } from '../enums/route';
import { PRODUCT_DISPLAY_PRICE_TYPE } from '../enums/widgetProduct';

import {
	WIDGET_VISIBILITY,
	WIDGET_TYPE,
	WIDGET_TYPE_LABEL,
} from '../enums/widgets';

const DEFAULT_WIDGET_FIELDS = [
	{ key: 'type', label: 'Type', class: 'col-type' },
	{ key: 'linkTo', label: 'Link To', class: 'col-link-to' },
];

const OTHER_WIDGET_FIELDS = [
	{ key: 'name', label: 'Name', class: 'col-name' },
	{ key: 'type', label: 'Type', class: 'col-type' },
	{ key: 'linkTo', label: 'Link To', class: 'col-link-to' },
	{ key: 'websiteVisibility', label: 'Website Visibility', class: 'col-website-visibility' },
	{ key: 'mobileVisibility', label: 'Mobile Visibility', class: 'col-mobile-visibility' },
	{ key: 'edit', label: '', class: 'col-edit' },
];

export default {
	name: 'WidgetList',

	components: {
		ModalCreateWidget,
	},

	props: {
		requiredCategory: {
			type: Boolean,
			default: false,
		},
		isMicrosite: {
			type: Boolean,
			default: false,
		},
		otherWidgetDescription: {
			type: String,
			default: 'All widgets are shown on Homepage, you can create widgets (except flash sale which will be auto-linked), re-order widgets and set visibility.',
		},
	},

	data() {
		return {
			WIDGET_TYPE,
			WIDGET_TYPE_LABEL,
			DEFAULT_WIDGET_FIELDS,
			OTHER_WIDGET_FIELDS,
			localLoading: false,
			localCategory: null,
			widgetDefaultTableData: [],
			widgetOtherTableData: [],
			isEdit: false,
			editedId: null,
			editedType: '',
			modalTitle: '',
		};
	},

	computed: {
		...mapState('widgets', {
			list: 'list',
		}),

		...mapGetters({
			getDefaultWidget: 'widgets/getDefaultWidget',
			getOtherWidgets: 'widgets/getOtherWidgets',
			getWidgetCategory: 'widgets/getWidgetCategory',
			categoryLists: 'categories/categoryLists',
		}),

		isLoading() {
			return this.list.isLoading || this.localLoading;
		},
		categoryOptions() {
			return this.categoryLists.map(transformedCategoryDropdownOption);
		},
	},

	async created() {
		try {
			this.localLoading = true;
			await Promise.all([
				this.fetchWidgets(),
				this.getCategories(),
			]);
		} finally {
			this.localLoading = false;
		}

		if (this.requiredCategory) {
			this.localCategory = this.categoryOptions.find((option) => option.value === this.getWidgetCategory.categoryId);
		}
	},

	methods: {
		...mapActions({
			getWidgets: 'widgets/getWidgets',
			getMicrositeWidgets: 'widgets/getMicrositeWidgets',
			getCategories: 'categories/getCategories',
			sortWidgets: 'widgets/sortWidgets',
			postWidget: 'widgets/postWidget',
			editWidget: 'widgets/editWidget',
			editWidgetCategory: 'widgets/editWidgetCategory',
			deleteWidget: 'widgets/deleteWidget',
		}),

		widgets(params) {
			return this.isMicrosite ? this.getMicrositeWidgets(params) : this.getWidgets(params);
		},

		async fetchWidgets() {
			const params = {
				per_page: 'all',
			};

			await this.widgets(params);

			this.widgetDefaultTableData = this.getDefaultWidget;
			this.widgetOtherTableData = this.getOtherWidgets;
		},

		openModalCreate(type) {
			this.isEdit = false;

			this.modalTitle = `Create ${WIDGET_TYPE_LABEL[type]} widget`;
			this.editedType = type;
			this.$refs['modal-create'].open();
		},

		handleUpdateOrder(sortedList) {
			this.widgetOtherTableData = sortedList;
		},

		async handleCreateAndEdit(params) {
			if (this.isEdit) {
				await this.editWidget({
					id: this.editedId,
					isMicrosite: this.isMicrosite,
					params,
				});
			} else {
				await this.postWidget({
					isMicrosite: this.isMicrosite,
					params,
				});
			}
		},

		handleEdit(id) {
			const editedItem = this.list.data.find((item) => item.id === id);
			const type = WIDGET_TYPE_LABEL[editedItem.type];

			this.isEdit = true;
			this.editedId = id;

			this.modalTitle = `${type} widget`;
			this.editedType = editedItem.type;
			this.$refs['modal-create'].open();
			this.$refs['modal-create'].name = editedItem.name;
			this.$refs['modal-create'].titleEN = editedItem.titleEN;
			this.$refs['modal-create'].titleTH = editedItem.titleTH;
			this.$refs['modal-create'].link = editedItem.link;
			this.$refs['modal-create'].shortDescription = editedItem.shortDescription;
			this.$refs['modal-create'].websiteVisibility = editedItem.websiteVisibility.value === WIDGET_VISIBILITY.true.value;
			this.$refs['modal-create'].mobileVisibility = editedItem.mobileVisibility.value === WIDGET_VISIBILITY.true.value;
			this.$refs['modal-create'].sideToggleType = editedItem.sideToggleType;
			this.$refs['modal-create'].displayPriceType = editedItem.displayPriceType === PRODUCT_DISPLAY_PRICE_TYPE.INSTALLMENT; // Price = false, Installment = true
		},

		handleRemove() {
			this.$refs['modal-create'].close();
			this.$refs['modal-remove'].open();
		},

		handleLink(wiggetId) {
			let linkTo = '';
			let query = {};
			let params = {};

			const widget = this.list.data.find((item) => item.id === wiggetId);

			// widget not found
			if (!widget) {
				return {};
			}

			const { type, data } = widget;
			switch (type) {
				case WIDGET_TYPE.hero:
					linkTo = 'BannerHeroList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.productCategory:
					linkTo = 'BannerProductCategory';
					break;
				case WIDGET_TYPE.highlight:
					linkTo = 'BannerHighlightList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.small:
					linkTo = 'BannerSmallList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.shortcut:
					linkTo = 'BannerShortcutList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.content:
					linkTo = 'MicrositeBannerContentList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.flashSale:
					linkTo = 'FlashSaleEdit';
					params = data.id ? { id: data.id } : {};
					break;
				case WIDGET_TYPE.products:
					linkTo = data.length ? 'MarketingCampaignEdit' : 'MarketingCampaignList';
					params = data.length ? { id: data[0].id } : {};
					break;
				case WIDGET_TYPE.brandBanner:
					linkTo = 'BrandBannerEdit';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.productHighlight:
					linkTo = 'ProductHighlightEdit';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.shopByBrand:
					linkTo = 'BannerShopByBrandList';
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.highlightContentBlock:
					linkTo = ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_LIST;
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.stripeContentBlock:
					linkTo = ROUTE_NAME.CONTENT_BLOCK_STRIPE_LIST;
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				case WIDGET_TYPE.textWithImageContentBlock:
					linkTo = ROUTE_NAME.CONTENT_BLOCK_TEXT_WITH_IMAGE_LIST;
					query = { widget_id: wiggetId, is_visible: 1 };
					break;
				default:
					break;
			}

			return {
				name: linkTo,
				query,
				params,
			};
		},

		handleReset() {
			this.$router.go({
				path: 'Homepage',
				force: true,
			});
		},

		handleSubmit() {
			const widgetDefaultIds = this.widgetDefaultTableData.map((item) => item.id);
			const widgetOtherIds = this.widgetOtherTableData.map((item) => item.id);
			this.sortWidgets([...widgetDefaultIds, ...widgetOtherIds]);
			this.editWidgetCategory({
				widgetId: this.getWidgetCategory.id,
				categoryId: this.localCategory.value,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.widget-title {
		font-size: rem($font-size-subtitle);
	}

	.table-wrapper {
		padding: 0 rem(40);
	}

	.link-status {
		font-size: rem($font-size-caption);
		color: $color-black-45;
	}

	.icon-pencil {
		color: $color-black-25;
	}

	.widget-description {
		font-size: rem($font-size-body-2);
		color: $color-black-45;
	}

	.category-option {
		max-width: rem(292);
	}

	::v-deep {
		.main-wrapper {
			width: auto;
			padding: 0 rem(24);
		}

		.widget-table {
			&-default {
				// Override column width
				.col-type {
					width: 50%;
				}

				.col-linkTo {
					width: 50%;
				}

				thead th {
					background-color: $color-gray-200;
					border-bottom: none;

					&:first-child {
						border-top-left-radius: 4px;
					}

					&:last-child {
						border-top-right-radius: 4px;
					}
				}

				tbody td {
					border-bottom: 1px solid $color-gray-200;

					&:first-child {
						border-left: 1px solid $color-gray-200;
						border-bottom-left-radius: 4px;
					}

					&:last-child {
						border-right: 1px solid $color-gray-200;
						border-bottom-right-radius: 4px;
					}
				}
			}

			&-other {
				// Override column width
				.col-name {
					width: 25%;
				}

				.col-type {
					width: 25%;
				}

				.col-visibility {
					width: rem(48);
				}

				.col-edit {
					width: rem(16);
				}
			}
		}
	}
</style>
