<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		data-test-id="modal"
		@update:show="close"
	>
		<span>{{ title }}</span>
		<template #header-wrapper>
			<ModalHeaderWrapper :title="title" @onClose="close" />
		</template>
		<template #default>
			<CForm @submit.prevent="handleSubmit">
				<CInput
					v-show="!isFlashSale"
					ref="name"
					v-model.trim="$v.name.$model"
					:is-valid="!$v.name.$error && null"
					invalid-feedback="Widget is required"
					label="Widget name*"
				/>
				<CInput
					v-show="isShowShortDescription"
					ref="shortDescription"
					v-model.trim="shortDescription"
					label="Short description"
				/>
				<CRow v-if="isShowTitle">
					<CCol>
						<CInput
							ref="titleEN"
							v-model.trim="titleEN"
							label="Title (EN) (optional)"
						/>
					</CCol>
					<CCol>
						<CInput
							ref="titleTH"
							v-model.trim="titleTH"
							label="Title (TH) (optional)"
						/>
					</CCol>
				</CRow>
				<CInput
					v-if="isShowLink"
					ref="link"
					v-model.trim="$v.link.$model"
					:is-valid="!$v.link.$error && null"
					invalid-feedback="Please enter a valid Website URL"
					label="Link (optional)"
				/>
				<CRow class="d-flex">
					<CCol>
						<label class="toggle-label" for="widget-visibility">
							Website visibility
						</label>
					</CCol>
					<CCol>
						<label class="toggle-label" for="widget-visibility">
							Mobile visibility
						</label>
					</CCol>
				</CRow>
				<CRow class="d-flex">
					<CCol class="d-flex align-items-center">
						<CSwitch
							id="widget-visibility"
							:checked.sync="websiteVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
						/>
						<span>{{ websiteVisibilityText }}</span>
					</CCol>
					<CCol class="d-flex align-items-center">
						<CSwitch
							id="widget-visibility"
							:checked.sync="mobileVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
						/>
						<span>{{ mobileVisibilityText }}</span>
					</CCol>
				</CRow>
				<template v-if="isShowSideToggleType">
					<CRow class="d-flex mt-3">
						<CCol>
							<label class="toggle-label" for="widget-visibility">
								Content alignment
							</label>
						</CCol>
					</CRow>
					<CRow class="d-flex">
						<CCol>
							<BaseButtonGroup
								v-model="sideToggleType"
								:list="CONTENT_ALIGN_OPTIONS"
							/>

							<p class="mt-3 side-toggle-type-helper"><b>The text box</b> will be relocated to the <b class="text-capitalize">{{ sideToggleType }}</b> while the <b>Image</b> will be shifted to the <b class="text-capitalize">{{ oppositeSideToggleType }}</b>.</p>
						</CCol>
					</CRow>
				</template>
				<template v-if="isProductWidget">
					<CRow class="d-flex mt-3">
						<CCol>
							<label class="toggle-label" for="display-product-price">
								Display product price
							</label>
						</CCol>
					</CRow>
					<CRow class="d-flex">
						<CCol class="d-flex align-items-center">
							<CSwitch
								id="display-product-price"
								:checked.sync="displayPriceType"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
							/>
							<span>{{ productDisplayPriceText }}</span>
						</CCol>
					</CRow>
				</template>
			</CForm>
		</template>
		<template #footer>
			<a
				v-if="isShowRemoveButton"
				href="#"
				class="btn-remove mr-auto"
				@click="$emit('onRemove')"
			>
				Remove widget
			</a>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="$v.$invalid || isSubmitting"
				class="modal-button transparent"
				color="primary"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				{{ isEdit ? 'Save changes' : 'Create' }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import { WIDGET_TYPE } from '../enums/widgets';
import { CONTENT_ALIGN, CONTENT_ALIGN_OPTIONS } from '../enums/contentBlocks';
import { PRODUCT_DISPLAY_PRICE_TYPE } from '../enums/widgetProduct';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';

export default {
	name: 'ModalCreateWidget',

	components: {
		ModalHeaderWrapper,
	},

	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isMicrosite: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Untitled',
		},
		type: {
			type: String,
			default: null,
		},
		handleCreate: {
			type: Function,
			default: () => {},
		},
	},

	data() {
		return {
			CONTENT_ALIGN,
			CONTENT_ALIGN_OPTIONS,

			isShow: false,
			isSubmitting: false,
			name: '',
			titleTH: '',
			titleEN: '',
			link: '',
			shortDescription: null,
			websiteVisibility: false,
			mobileVisibility: false,
			sideToggleType: CONTENT_ALIGN.RIGHT,
			displayPriceType: false,
			WIDGET_TYPE,
		};
	},

	validations: {
		name: {
			required,
		},
		link: {
			url,
		},
	},

	computed: {
		websiteVisibilityText() {
			return this.websiteVisibility ? 'On' : 'Off';
		},
		mobileVisibilityText() {
			return this.mobileVisibility ? 'On' : 'Off';
		},
		isFlashSale() {
			return this.type === WIDGET_TYPE.flashSale;
		},
		isShowRemoveButton() {
			return this.isEdit && ![WIDGET_TYPE.flashSale, WIDGET_TYPE.brandBanner, WIDGET_TYPE.productHighlight, WIDGET_TYPE.productCategory].includes(this.type);
		},
		isShowShortDescription() {
			return this.type === WIDGET_TYPE.products && this.isMicrosite;
		},
		isShowTitle() {
			return [
				WIDGET_TYPE.products,
				WIDGET_TYPE.small,
				WIDGET_TYPE.shopByBrand,
				WIDGET_TYPE.shortcut,
				WIDGET_TYPE.productCategory,
				WIDGET_TYPE.stripeContentBlock,
			].includes(this.type);
		},
		isShowLink() {
			return [WIDGET_TYPE.small, WIDGET_TYPE.shopByBrand, WIDGET_TYPE.shortcut].includes(this.type);
		},
		isShowSideToggleType() {
			return this.type === WIDGET_TYPE.textWithImageContentBlock;
		},
		oppositeSideToggleType() {
			return this.sideToggleType === CONTENT_ALIGN.LEFT ? CONTENT_ALIGN.RIGHT : CONTENT_ALIGN.LEFT;
		},
		isProductWidget() {
			return this.type === WIDGET_TYPE.products;
		},
		productDisplayPriceText() {
			return this.displayPriceType ? PRODUCT_DISPLAY_PRICE_TYPE.INSTALLMENT : PRODUCT_DISPLAY_PRICE_TYPE.PRICE;
		},
	},

	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();
			this.$refs.name.$el.querySelector('input').focus();
		},

		close() {
			this.$v.$reset();
			this.name = '';
			this.titleTH = '';
			this.titleEN = '';
			this.link = '';
			this.shortDescription = '';
			this.isShow = false;
			this.isSideToggleType = false;
			this.displayPriceType = false;
		},

		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) return;
			this.isSubmitting = true;

			try {
				const data = {
					name: this.name,
					title_en: this.titleEN,
					title_th: this.titleTH,
					link: this.link,
					type: this.type,
					is_active: this.websiteVisibility,
					is_mobile_active: this.mobileVisibility,
					short_description: this.shortDescription,
					side_toggle_type: this.sideToggleType,
				};

				if (this.isProductWidget) {
					data.selling_type = this.displayPriceType ? PRODUCT_DISPLAY_PRICE_TYPE.INSTALLMENT : PRODUCT_DISPLAY_PRICE_TYPE.PRICE;
				}

				await this.handleCreate(data);

				this.$v.$reset();
				this.isShow = false;
				this.name = '';

				this.$emit('onSuccess');
			} catch (e) {
				this.$emit('onFailure');
			}

			this.isSubmitting = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.toggle-visibility {
	margin-right: rem(12);
}

.side-toggle-type-helper {
	@include typo-body-2;

	color: $color-black-45;
	margin: 0;
}
</style>
